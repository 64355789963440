<template>
  <v-card>
    <StandardCardHeader
      :go-to-link="'admin-warrant-list'"
      :title="'Create warrant'"
      :button-text="'Close'"
      :show-action-button="true"
      :link-button-color="'error'"
      @action="save"
    />
    <v-row class="px-2 mx-0 pt-3">
      <v-card-text>
        <v-row class="border-bottom-2px-silver text-h6 mb-1">
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <div>
              {{ $t('Warrant number') }} <strong>{{ platform_reference_number }}</strong>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <div>
              {{ $t('Warrant code') }} <strong>{{ warrant_code }}</strong>
            </div>
          </v-col>
          <v-col
            v-if="created_at"
            cols="12"
            md="6"
            lg="3"
          >
            <div>
              {{ $t('Date') }} <strong>{{ formatDateToLocal(created_at)  }}</strong>
            </div>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          lazy-validation
          class="ma-0 pt-6"
        >
          <v-row class="mb-1">
            <v-col
              cols="12"
              md="6"
              lg="3"
              class="bordered-column"
            >
              <div class="bordered-column-title">
                {{ $t('Client data') }}
              </div>
              <v-combobox
                v-model="company"
                :items="companies"
                item-text="name"
                item-value="id"
                clearable
                type="search"
                dense
                outlined
                :label="$t('Company')"
                class="w-full mb-1"
                hide-details
                :no-data-text="$t('noDataText')"
                :rules="[validator.required]"
                :disabled="isNotAllowedToEdit(true)"
                @update:search-input="filterCompanies"
                @click:clear="resetCompanyData"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-combobox>
              <div class="position-relative d-block">
                <v-text-field
                  v-model="referent_name"
                  dense
                  outlined
                  :label="$t('Referent')"
                  class="w-full mb-1"
                  hide-details
                  :disabled="isNotAllowedToEdit(true)"
                >
                </v-text-field>
                <v-icon
                  v-if='isNotAllowedToEdit(true)'
                  style="top: 7px; right: 2px; position: absolute"
                  @click.prevent="copyText(referent_name)">
                  {{ icons.mdiContentCopy }}
                </v-icon>
              </div>
              <div class="position-relative d-block">
                <v-text-field
                  v-model="phone"
                  dense
                  outlined
                  :label="$t('Phone')"
                  class="w-full mb-1"
                  hide-details
                  :disabled="isNotAllowedToEdit(true)"
                >
                </v-text-field>
                <v-icon
                  v-if='isNotAllowedToEdit(true)'
                  style="top: 7px; right: 2px; position: absolute"
                  @click.prevent="copyText(phone)">
                  {{ icons.mdiContentCopy }}
                </v-icon>
              </div>
              <div class="position-relative d-block">
                <v-text-field
                  v-model="address"
                  dense
                  outlined
                  :label="$t('Address')"
                  class="w-full mb-1"
                  hide-details
                  :disabled="isNotAllowedToEdit(true)"
                >
                </v-text-field>
                <v-icon
                  v-if='isNotAllowedToEdit(true)'
                  style="top: 7px; right: 2px; position: absolute"
                  @click.prevent="copyText(address)">
                  {{ icons.mdiContentCopy }}
                </v-icon>
              </div>
              <div class="position-relative d-block">
                <v-text-field
                  v-model="email"
                  dense
                  outlined
                  :label="$t('Email')"
                  class="w-full mb-1"
                  hide-details
                  :rules="[validator.required, validator.emailValidator]"
                  :disabled="isNotAllowedToEdit(true)"
                >
                </v-text-field>
                <v-icon
                  v-if='isNotAllowedToEdit(true)'
                  style="top: 7px; right: 2px; position: absolute"
                  @click.prevent="copyText(email)">
                  {{ icons.mdiContentCopy }}
                </v-icon>
              </div>
              <div class="position-relative d-block">
                <v-text-field
                  v-model="company_identification_number"
                  dense
                  outlined
                  :label="$t('ID number')"
                  class="w-full mb-1"
                  hide-details
                  :disabled="isNotAllowedToEdit(true)"
                >
                </v-text-field>
                <v-icon
                  v-if='isNotAllowedToEdit(true)'
                  style="top: 7px; right: 2px; position: absolute"
                  @click.prevent="copyText(company_identification_number)">
                  {{ icons.mdiContentCopy }}
                </v-icon>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              class="bordered-column"
            >
              <div class="bordered-column-title">
                {{ $t('Job info') }}
              </div>
              <v-text-field
                v-model="job.file_path"
                dense
                outlined
                :label="$t('File')"
                class="w-full mb-1"
                hide-details
                :disabled="isNotAllowedToEdit(true)"
              >
              </v-text-field>
              <v-select
                v-model="job.payment_method"
                dense
                outlined
                :label="$t('Payment')"
                class="w-full mb-1"
                :items="$store.state.paymentMethods"
                item-value="key"
                :item-text="item => $t(item.name)"
                hide-details
                :rules="[validator.required]"
                :disabled="isNotAllowedToEdit(true)"
              >
              </v-select>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="job.deadline"
                    dense
                    outlined
                    :label="$t('Deadline')"
                    class="w-full mb-1"
                    v-bind="attrs"
                    hide-details
                    :disabled="isNotAllowedToEdit(true)"
                    :rules="[validator.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="job.deadline"
                  locale="srLatn"
                  :disabled="isNotAllowedToEdit(true)"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                v-model="job.discount"
                dense
                outlined
                :label="$t('Discount')"
                class="w-full mb-1"
                hide-details
                :disabled="isNotAllowedToEdit(true)"
              >
              </v-text-field>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  class="pb-4"
                >
                  <v-checkbox
                    v-model="by_post"
                    :label="$t('By post')"
                    hide-details
                    dense
                    :disabled="isNotAllowedToEdit(true)"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="invoice"
                    :label="$t('Invoice')"
                    hide-details
                    dense
                    :disabled="isNotAllowedToEdit(true)"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="is_paid"
                    :label="$t('Paid')"
                    hide-details
                    dense
                    :disabled="isNotAllowedToEdit(true)"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-checkbox
                    v-model="add_to_account"
                    :label="$t('Add to account')"
                    hide-details
                    dense
                    :disabled="isNotAllowedToEdit(true)"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="clients_material"
                    :label="$t('Client provided material')"
                    hide-details
                    dense
                    :disabled="Number.isInteger(warrantId) || isNotAllowedToEdit(true)"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              class="bordered-column"
            >
              <div class="bordered-column-title">
                {{ $t('Location data') }}
              </div>
              <v-select
                v-model="location.received_at_location"
                :items="$store.getters.getStocks"
                dense
                outlined
                class="w-full mb-1"
                :label="$t('Location of creation')"
                hide-details
                :no-data-text="$t('noDataText')"
                item-value="id"
                item-text="name"
                :rules="[validator.required]"
                :disabled="warrantId && !isNaN(location.received_at_location) || isNotAllowedToEdit(true)"
              >
              </v-select>
              <v-select
                v-model="location.takeover_location"
                :items="$store.getters.getStocks"
                dense
                outlined
                class="w-full mb-1"
                :label="$t('Takeover location')"
                hide-details
                :no-data-text="$t('noDataText')"
                item-value="id"
                item-text="name"
                :disabled="isNotAllowedToEdit(true)"
              >
              </v-select>
              <v-select
                v-model="location.created_by"
                :items="$store.getters.getUsers"
                dense
                outlined
                class="w-full mb-1"
                :label="$t('Created by')"
                hide-details
                :no-data-text="$t('noDataText')"
                item-value="id"
                item-text="name"
                :disabled="isNotAllowedToEdit(true)"
              >
              </v-select>
              <v-text-field
                v-model="location.shelf"
                :label="$t('Shelf')"
                dense
                outlined
                class="w-full mb-1"
                :disabled="isNotAllowedToEdit(true)"
              >
              </v-text-field>
              <span>{{ $t('Price') }}: </span>
              <v-chip>
                {{ total }}
              </v-chip>
              <v-chip v-if="job.payment_method === 'invoice'">
                {{ is_invoiced ? $t('Invoiced') : $t('Not invoiced') }}
              </v-chip>

            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              class="bordered-column"
            >
              <div class="bordered-column-title">
                {{ $t('Actions') }}
              </div>
              <v-btn
                dark
                class="ma-1 w-full"
                :color="informed ? 'success' : 'error'"
                :disabled="isNotAllowedToEdit()"
                @click.prevent="toggleInformed()"
              >
                {{ $t('Informed') }}
              </v-btn>
              <v-btn
                dark
                class="ma-1 w-full"
                :color="message_sent ? 'success' : 'error'"
                :disabled="isNotAllowedToEdit(true) && userRole !== 'admin'"
                @click.prevent="toggleMessageSent()"
              >
                {{ $t('Message') }}
              </v-btn>
              <v-btn
                dark
                class="ma-1 w-full"
                :color="is_delivered ? 'success' : 'error'"
                :disabled="isNotAllowedToEdit()"
                @click.prevent="toggleIsDelivered()"
              >
                {{ $t('Delivered') }}
              </v-btn>
              <v-btn
                v-if="warrantId"
                dark
                class="ma-1 w-full"
                :to="{name:'admin-warrant-barcode', params:
                  {
                    id: warrantId,
                    reference: platform_reference_number,
                    code: warrant_code,
                    company: company.name,
                    contactPerson: referent_name,
                    phone: phone,
                    shelf: location.shelf,
                    products: items,
                    price: total
                  }
                }"
              >
                <span>{{ $t('print label') }}</span>
              </v-btn>
<!--              <v-btn-->
<!--                dark-->
<!--                x-large-->
<!--                class="ma-1 w-full"-->
<!--                color="dark"-->
<!--                :disabled="isNotAllowedToEdit()"-->
<!--                @click.prevent="save"-->
<!--              >-->
<!--                {{ $t('Save') }}-->
<!--              </v-btn>-->
              <v-btn
                dark
                x-large
                class="ma-1 w-full"
                color="dark"
                :disabled="isNotAllowedToEdit()"
                @click.prevent="changeStatus(status === 'complete' ? 'active' : 'complete')"
              >
                {{ status === 'complete' ? $t('Activate') : $t('Finish') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="note"
                rows="3"
                :label="$t('Note')"
                dense
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, index) in items"
            :key="`${index}-${item.id}`"
            class="my-4 my-xl-2"
          >
            <v-col
              cols="12"
              lg="2"
              class="d-inline-flex my-1"
            >
              <v-btn
                class="mx-2"
                fab
                small
                color="error"
                :disabled="isNotAllowedToEdit(true)"
                @click.prevent="removeItemsRow(index, item.id)"
              >
                <v-icon dark>
                  {{ icons.mdiMinus }}
                </v-icon>
              </v-btn>
              <v-autocomplete
                v-model="item.process"
                :label="$t('Process')"
                dense
                outlined
                hide-details
                :items="$store.state.warrantCategories"
                :item-text="item => $t(item.name)"
                item-value="key"
                :rules="[validator.required]"
                :disabled="isNotAllowedToEdit(true)"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              lg="2"
              class="my-1"
            >
              <v-combobox
                v-model="item.name"
                :label="$t('Product')"
                dense
                outlined
                hide-details
                :items="$store.getters.getItems"
                item-text="name"
                item-value="name"
                :rules="[validator.required]"
                :disabled="isNotAllowedToEdit(true)"
                @input="updateCurrentItem(index, 'name')"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              lg="1"
              class="my-1"
            >
              <v-text-field
                v-model="item.price"
                :label="$t('Price')"
                dense
                outlined
                step="0.01"
                min="0"
                hide-details
                :rules="[validator.required]"
                :disabled="isNotAllowedToEdit()"
                @input="formatPrice(index)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="1"
              class="my-1"
            >
              <v-text-field
                :value="item.price && (+(item.price).replaceAll(',', '') * 1.2).toLocaleString('en-GB', { maximumFractionDigits: 2 })"
                :label="`${$t('Price')} sa PDV`"
                dense
                outlined
                step="0.01"
                min="0"
                hide-details
                :disabled="isNotAllowedToEdit(true)"
                @input="updatePriceVAT($event, index)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="1"
              class="my-1"
            >
              <v-text-field
                v-model="item.quantity"
                :label="$t('Quantity')"
                dense
                outlined
                type="number"
                step="0.01"
                min="0"
                hide-details
                :rules="[validator.required]"
                :disabled="isNotAllowedToEdit(true)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="1"
              class="my-1"
            >
              <v-autocomplete
                v-model="item.user_id"
                :label="$t('Employee')"
                dense
                outlined
                hide-details
                :items="$store.getters.getUsers"
                item-value="id"
                item-text="name"
                :no-data-text="$t('noDataText')"
                :disabled="isNotAllowedToEdit(true)"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              class="my-1"
            >
              <v-autocomplete
                v-model="item.equipment_id"
                :label="$t('Machine')"
                dense
                outlined
                hide-details
                :items="$store.getters.getEquipments"
                item-value="id"
                item-text="name"
                :no-data-text="$t('noDataText')"
                :disabled="isNotAllowedToEdit(true)"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="item.id"
              cols="12"
              lg="1"
              class="d-flex align-content-lg-end my-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    class="mr-1"
                    color="primary"
                    v-bind="attrs"
                    :disabled="isNotAllowedToEdit(true)"
                    @click="showMaterialsModal(item.id)"
                    v-on="on"
                  >
                    <v-icon dark>
                      {{ icons.mdiWarehouse }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Material') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    class="mr-1"
                    color="primary"
                    v-bind="attrs"
                    :disabled="isNotAllowedToEdit()"
                    @click="showNoteModal(item.id)"
                    v-on="on"
                  >
                    <v-icon dark>
                      {{ icons.mdiEmailAlert }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Note') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    class="mr-1"
                    color="primary"
                    v-bind="attrs"
                    :disabled="isNotAllowedToEdit(true)"
                    @click="mapToPlanner(item.id)"
                    v-on="on"
                  >
                    <v-icon dark>
                      {{ icons.mdiShare }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Dodaj u planer') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    :color="item.status === 'active' ? 'secondary' : 'success'"
                    class="mr-1"
                    :disabled="item.status === 'active' || isNotAllowedToEdit()"
                    v-bind="attrs"
                    @click.prevent="startProcess(item.id, index)"
                    v-on="on"
                  >
                    <v-icon dark>
                      {{ icons.mdiPlay }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Start') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    :color="item.status !== 'active' ? 'secondary' : 'error'"
                    :disabled="item.status !== 'active' || isNotAllowedToEdit()"
                    v-bind="attrs"
                    @click.prevent="stopProcess(item.id, index)"
                    v-on="on"
                  >
                    <v-icon dark>
                      {{ icons.mdiStop }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Stop') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            class="my-6"
          >
            <v-col
              cols="12"
              class="mx-2"
            >
              <v-btn
                fab
                small
                color="success"
                :disabled="isNotAllowedToEdit(true)"
                @click.prevent="addItemsRow()"
              >
                <v-icon dark>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-row>
    <v-dialog
      v-model="showMaterials"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span
            class="headline"
          >{{ $t('edit') }} {{ $t('Material') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              class="ma-0 pt-6"
            >
              <v-row class="mb-2 border-bottom-2px-silver">
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                >
                  {{ $t('#') }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  {{ $t('Category') }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  {{ $t('Material') }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  class="text-right"
                >
                  {{ $t('Price') }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                >
                  {{ $t('Unit') }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                >
                  {{ $t('Quantity') }}
                </v-col>
              </v-row>
              <v-row
                v-for="(material, index) in currentItem.materials"
                :key="index"
                class="py-2"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  class="pt-2"
                >
                  {{ (index + 1) }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-autocomplete
                    v-model="material.stockItemCategoryId"
                    :items="$store.getters.getStockItemCategories"
                    dense
                    outlined
                    :no-data-text="$t('noDataText')"
                    hide-details
                    :label="$t('Category')"
                    item-value="id"
                    item-text="name"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-autocomplete
                    :id="`material-item-${index}`"
                    v-model="material.id"
                    :items="filterProductByCategory(material, index)"
                    dense
                    outlined
                    :no-data-text="$t('noDataText')"
                    hide-details
                    item-value="id"
                    item-text="name"
                    @change="generateMaterialItem(material.id, index)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  class="pt-2 text-right"
                >
                  {{ material.price ? (+material.price).toLocaleString('en-GB', { maximumFractionDigits: 2}) : material.price }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  class="pt-2"
                >
                  {{ material.unit }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                >
                  <v-text-field
                    v-if="material.unit === 'kom'"
                    :id="`material-quantity-${index}`"
                    v-model="material.quantity"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[validator.required]"
                  />
                  <v-text-field
                    v-if="material.unit === 'cm2'"
                    v-model="material.multiplier1"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[validator.required]"
                    :label="material.unit === 'cm2' ? 'Dužina' : ''"
                    class="mt-1"
                  />
                  <v-text-field
                    v-if="material.unit === 'cm2'"
                    v-model="material.multiplier2"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[validator.required]"
                    :label="material.unit === 'cm2' ? 'Širina' : ''"
                    class="mt-1"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                >
                  <v-btn
                    fab
                    x-small
                    color="error"
                    @click.prevent="removeMaterial(currentItem.id, index)"
                  >
                    <v-icon dark>
                      {{ icons.mdiMinus }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="w-full">
                <v-col
                  class="pt-6"
                  cols="12"
                >
                  <v-btn
                    fab
                    small
                    color="success"
                    class="mr-1"
                    @click.prevent="addMaterial(currentItem.id)"
                  >
                    <v-icon dark>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeMaterialModal()"
          >
            {{ $t('Close') }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveMaterial()"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showItemNote"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="headline"
          >{{ $t('edit') }} {{ $t('Note') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              class="py-2"
            >
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="pt-2"
              >
                <v-textarea
                  v-model="currentItem.note"
                  outlined
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row class="w-full">
              <v-col
                class="pt-6"
                cols="12"
              >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="() => showItemNote = false"
          >
            {{ $t('Close') }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveNote()"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarMessage ? snackbarMessage : $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiCalendar,
  mdiCircleEditOutline,
  mdiCloseCircle,
  mdiCloseOutline,
  mdiMinus,
  mdiPencilCircle,
  mdiPlay,
  mdiPlus,
  mdiStop,
  mdiEmailAlert,
  mdiWarehouse,
  mdiShare,
  mdiContentCopy,
} from '@mdi/js'
import axiosIns from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import { isObject } from '@core/utils'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import { formatDateToLocal } from '../../../services/dateTimeService'

export default {
  components: { StandardCardHeader },
  data() {
    const items = [{}]
    const currentItem = {}
    const materialList = []
    const originalMaterialList = []
    const originalMaterials = []
    const location = {}
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    if (!this.$route.params.id) {
      location.received_at_location = +userData?.location
      location.takeover_location = +userData?.location
      location.created_by = location.created_by ? location.created_by : userData.id
    }

    return {
      warrantId: false,
      dateMenu: false,
      company: '',
      referent_name: null,
      address: null,
      phone: null,
      email: null,
      company_identification_number: null,
      companies: [],
      job: {},
      created_at: null,
      location,
      snackbar: false,
      snackbarMessage: '',
      by_post: false,
      invoice: false,
      is_invoiced: false,
      add_to_account: false,
      clients_material: false,
      is_paid: false,
      informed: false,
      total: 0,
      message_sent: false,
      is_delivered: false,
      note: null,
      items,
      currentItem,
      materialList,
      originalMaterialList,
      deleted_items: [],
      platform_reference_number: '',
      warrant_code: '',
      showMaterials: false,
      showItemNote: false,
      originalMaterials,
      removedMaterials: [],
      status: '',
      userData,
      userRole,
      icons: {
        mdiShare,
        mdiWarehouse,
        mdiCloseOutline,
        mdiCircleEditOutline,
        mdiPencilCircle,
        mdiCloseCircle,
        mdiCalendar,
        mdiPlus,
        mdiMinus,
        mdiArrowUp,
        mdiArrowDown,
        mdiPlay,
        mdiStop,
        mdiEmailAlert,
        mdiContentCopy
      },
      validator: {
        emailValidator,
        required,
      },
    }
  },
  watch: {
    'location.received_at_location': {
      handler(val) {
        this.getMaterials(val)
      },
      deep: true,
    },
    company() {
      this.referent_name = this.referent_name ? this.referent_name : this.company.referent_name
      this.phone = this.phone ? this.phone : this.company.phone
      this.address = this.address ? this.address : this.company.address
      this.email = this.email ? this.email : this.company.email
      this.company_identification_number = this.company_identification_number
        ? this.company_identification_number
        : this.company.company_identification_number
    },
  },
  mounted() {
    if (this.$store.getters.getUsers.length === 0) {
      this.$store.dispatch('fetchUsers')
    }
    if (this.$store.getters.getEquipments.length === 0) {
      this.$store.dispatch('fetchEquipments')
    }
    if (this.$store.getters.getItems.length === 0) {
      this.$store.dispatch('fetchItems')
    }
    if (this.$store.getters.getStocks.length === 0) {
      this.$store.dispatch('fetchStocks')
    }
    if (this.$route.params.id) {
      this.warrantId = this.$route.params.id
      axiosIns({
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        url: `/admin/warrants/${this.$route.params.id}`,
      })
        .then(res => {
          const { warrant } = res.data
          this.company = warrant.company
          this.referent_name = warrant.referent_name
          this.phone = warrant.phone
          this.address = warrant.address
          this.email = warrant.email
          this.company_identification_number = warrant.company_identification_number
          this.job.file_path = warrant.file_path
          this.job.payment_method = warrant.payment_method
          this.job.deadline = warrant.deadline
          this.job.discount = warrant.discount
          this.job.price = warrant.price
          this.created_at = warrant.created_at
          this.by_post = Number.parseInt(warrant.by_post, 10)
          this.invoice = warrant.invoice
          this.is_invoiced = warrant.is_invoiced
          this.add_to_account = Number.parseInt(warrant.add_to_account, 10)
          this.clients_material = Number.parseInt(warrant.clients_material, 10)
          this.is_paid = warrant.is_paid
          this.location.received_at_location = +warrant.received_at_location
          this.location.takeover_location = +warrant.takeover_location
          this.location.shelf = warrant.shelf
          this.note = warrant.note
          this.items = warrant.warrant_containers.length
            ? warrant.warrant_containers.map(container => ({
              ...container,
              price: (+container.price).toLocaleString('en-GB', { maximumFractionDigits: 2 }),
              materials: JSON.parse(container.materials),
            }))
            : [{}]
          this.platform_reference_number = warrant.platform_reference_number
          this.warrant_code = warrant.warrant_code
          this.location.created_by = warrant.user
          this.informed = warrant.informed
          this.total = (warrant.warrant_containers.reduce((acc, curr) => acc + +((curr.price * 1.2).toFixed(2)) * curr.quantity, 0)).toLocaleString('en-GB', { maximumFractionDigits: 2 })
          this.message_sent = warrant.message_sent
          this.is_delivered = warrant.is_delivered
          this.status = warrant.status
          if (this.location.received_at_location) {
            this.getMaterials(this.location.received_at_location)
          }
        })
        .catch(() => {})
    }
  },
  methods: {
    formatDateToLocal,
    async copyText(text) {
      await navigator.clipboard.writeText(text)
      this.snackbarMessage = this.$t('Copied')
      this.snackbar = true
    },
    isNotAllowedToEdit(shouldDisableForUser = false) {
      if (this.userRole === 'super_admin') {
        return false
      }

      if (this.userRole === 'admin' && this.isCompleted()) {
        return true
      }

      if (this.isCompleted() || ((!this.userRole || this.userRole === 'user') && shouldDisableForUser)) {
        return true
      }

      return false
    },
    filterProductByCategory(currentItem) {
      return this.originalMaterialList
        .filter(m => +m.stock_item_category_id === +currentItem.stockItemCategoryId && m.stock_item_items.find(mi => mi.stock_id === this.location.received_at_location))
        .map(item => ({
          ...item,
          name: item.code ? `${item.code} - ${item.name}` : item.name,
        }))
    },
    mapToPlanner(id) {
      axiosIns.post(`/admin/planners/${id}/map`)
        .then(() => {
          this.snackbar = true
        })
        .catch(e => {
          this.snackbarMessage = this.$t('Item already exists')
          this.snackbar = true
          setTimeout(() => {
            this.snackbarMessage = ''
          }, 5500)
        })
    },
    showNoteModal(id) {
      this.currentItem = this.items.find(item => item.id === id)
      this.showItemNote = true
    },
    generateMaterialItem(materialId, index) {
      const existingMaterial = this.currentItem.materials.find((item, idx) => {
        if (item.id === materialId && index !== idx) {
          document.getElementById(`material-quantity-${idx}`).focus()
        }

        return item.id === materialId && index !== idx
      })
      if (existingMaterial) {
        this.currentItem.materials.splice(index, 1)
      } else {
        const materialItem = this.materialList.find(item => item.id === materialId)
        if (materialItem) {
          const currentItemMaterial = this.currentItem.materials.find((item, idx) => item.id === materialId && index === idx)
          currentItemMaterial.name = materialItem.name
          currentItemMaterial.unit = materialItem.unit
          currentItemMaterial.price = materialItem.price
          currentItemMaterial.quantity = 1
        }
      }
    },
    getMaterials(location, search = '') {
      axiosIns.get(`/admin/stocks/${location}/items?page=1&perPage=10000&search=${search}`)
        .then(res => {
          this.materialList = res.data.items.data
          this.originalMaterialList = this.materialList
        })
        .catch(e => {
          console.log('There was an error fetching material list: ', e)
        })
    },
    removeMaterial(itemId, index) {
      this.showMaterials = false
      this.currentItem.materials.splice(index, 1)
      this.showMaterials = true
    },
    addMaterial() {
      this.showMaterials = false
      const materialsLength = this.currentItem.materials.length
      let isLastItemEmpty = false
      if (materialsLength > 0) {
        if (!this.currentItem.materials[materialsLength - 1].id) {
          isLastItemEmpty = true
          document.getElementById(`material-item-${materialsLength - 1}`).focus()
        }
      }
      if (!isLastItemEmpty) {
        this.currentItem.materials.push({})
      }
      this.showMaterials = true
    },
    saveMaterial() {
      if (this.$refs.form.validate()) {
        this.save()
        this.showMaterials = false
      }
    },
    saveNote() {
      this.save()
      this.showItemNote = false
    },
    showMaterialsModal(itemId) {
      const currentItem = this.items.find(item => item.id === itemId)
      currentItem.materials = currentItem.materials ? currentItem.materials : []
      this.currentItem = currentItem
      this.originalMaterials = [...currentItem.materials]
      this.showMaterials = true
    },
    closeMaterialModal() {
      this.currentItem.materials = this.originalMaterials
      this.originalMaterials = []
      this.showMaterials = false
    },
    startProcess(id, index) {
      axiosIns({
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        url: `/admin/warrant-items/${id}/start`,
      }).then(res => {
        this.items[index] = {
          ...res.data.item,
          materials: typeof res.data.item.materials === 'string'
            ? JSON.parse(res.data.item.materials)
            : res.data.item.materials,
        }
        this.items.push()
        this.snackbar = true
      })
    },
    stopProcess(id, index) {
      axiosIns({
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        url: `/admin/warrant-items/${id}/stop`,
      }).then(res => {
        this.items[index] = {
          ...res.data.item,
          materials: typeof res.data.item.materials === 'string'
            ? JSON.parse(res.data.item.materials)
            : res.data.item.materials,
        }
        this.items.push()
        this.snackbar = true
      })
    },
    updateCurrentItem(index, prop) {
      const findItem = this.items[index]
      this.items.push()
      const currentItem = findItem && typeof findItem.name === 'object' ? {
        name: undefined,
        ...findItem,
        ...findItem.name,
        id: findItem.id,
      }
        : findItem

      const product = this.$store.getters.getItems.find((item => item[prop] === currentItem[prop]))
      if (product) {
        currentItem.code = product.code
        currentItem.name = product.name
        currentItem.price = (product.price / 1.2).toLocaleString('en-GB', { maximumFractionDigits: 2 })
      }
      currentItem.quantity = currentItem.quantity ? currentItem.quantity : 1
      currentItem.materials = currentItem.materials ? JSON.parse(currentItem.materials) : JSON.parse(product.materials)
      this.items[index] = currentItem
    },
    updatePriceVAT(value, index) {
      const currentItem = this.items[index]
      currentItem.price = (+`${value.replace(/,/g, '') / 1.2}`).toLocaleString('en-GB', { maximumFractionDigits: 2 })
      this.items.push()
    },
    formatPrice(index) {
      const currentItem = this.items[index]
      currentItem.price = (+currentItem.price.replace(/,/g, '')).toLocaleString('en-GB', { maximumFractionDigits: 2 })
      this.items.push()
    },
    resetCompanyData() {
      this.referent_name = ''
      this.email = ''
      this.phone = ''
      this.full_name = ''
      this.address = ''
      this.company_identification_number = ''
    },
    filterCompanies(filter) {
      if (filter && filter.length >= 2) {
        axiosIns.get(`/admin/companies?search=${filter}`)
          .then(res => {
            this.companies = res.data.companies.data
          })
          .catch()
      }
    },
    removeItemsRow(index, id) {
      const item = this.items.find(product => product.id === id)
      if (this.items[index].materials) {
        this.removedMaterials = [...this.removedMaterials, ...this.items[index].materials.map(material => ({
          ...material,
          quantity: material.quantity * item.quantity,
        }))]
      }
      this.items.splice(index, 1)
      if (id) {
        this.deleted_items.push(id)
      }
    },
    addItemsRow() {
      this.items.push({})
    },
    toggleInformed() {
      this.informed = !this.informed
    },
    toggleMessageSent() {
      this.message_sent = !this.message_sent
      if(this.isCompleted() && this.message_sent) {
        this.changeStatus(this.status);
      }
    },
    toggleIsPaid() {
      this.is_paid = !this.is_paid
    },
    toggleIsDelivered() {
      this.is_delivered = !this.is_delivered
    },
    changeStatus(status) {
      axiosIns({
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ status, message_sent: this.message_sent }),
        url: `/admin/warrants/${this.warrantId}`,
      }).then(res => {
        this.status = res.data.warrant.status
        this.snackbar = true
      })
    },
    isCompleted() {
      return this.status === 'complete'
    },
    save() {
      this.snackbarMessage = ''
      if (this.$refs.form.validate()) {
        const warrantContainers = this.items.map(item => (typeof item.name === 'string' ? {
          ...item,
          code: 'x',
          price: item.price.replace(/,/g, ''),
          materials: item.materials ? item.materials.map(material => {
            if (material.unit === 'cm2') {
              const multiplier1 = Number.parseFloat(material.multiplier1)
              const multiplier2 = Number.parseFloat(material.multiplier2)

              return {
                ...material,
                quantity: multiplier1 * multiplier2,
                multiplier1,
                multiplier2,
              }
            }

            return {
              ...material,
              multiplier1: undefined,
              multiplier2: undefined,
              quantity: Number.parseFloat(material.quantity),
            }
          })
            : [],
        }
          : {
            ...item,
            ...item.name,
            price: item.price.replace(/,/g, ''),
            materials: item.materials ? item.materials.map(material => {
              if (material.unit === 'cm2') {
                const multiplier1 = Number.parseFloat(material.multiplier1)
                const multiplier2 = Number.parseFloat(material.multiplier2)

                return {
                  ...material,
                  quantity: multiplier1 * multiplier2,
                  multiplier1,
                  multiplier2,
                }
              }

              return {
                ...material,
                multiplier1: undefined,
                multiplier2: undefined,
                quantity: Number.parseFloat(material.quantity),
              }
            })
              : [],
          }))
        const total = warrantContainers.reduce((acc, curr) => acc + curr.price * curr.quantity, 0)
        this.total = (total * 1.2).toLocaleString('en-GB', { maximumFractionDigits: 2 })
        const warrant = {
          company: this.company,
          referent_name: this.referent_name,
          phone: this.phone,
          address: this.address,
          email: this.email,
          company_identification_number: this.company_identification_number,
          file_path: this.job.file_path ?? null,
          payment_method: this.job.payment_method ?? null,
          deadline: this.job.deadline ?? null,
          discount: this.job.discount ?? 0,
          price: total,
          by_post: this.by_post,
          invoice: this.invoice,
          add_to_account: this.add_to_account,
          clients_material: this.clients_material,
          is_paid: this.is_paid,
          received_at_location: this.location.received_at_location ?? null,
          takeover_location: this.location.takeover_location ?? null,
          shelf: this.location.shelf ?? null,
          created_by: isObject(this.location.created_by) ? this.location.created_by.id : this.location.created_by,
          note: this.note ?? null,
          informed: this.informed,
          message_sent: this.message_sent,
          is_delivered: this.is_delivered,
          warrant_containers: warrantContainers,
          deleted_items: this.deleted_items,
          removed_materials: this.removedMaterials,
        }

        axiosIns({
          method: this.warrantId ? 'PUT' : 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(warrant),
          url: this.warrantId ? `/admin/warrants/${this.warrantId}` : '/admin/warrants',
        })
          .then(res => {
            this.warrantId = !this.warrantId ? res.data.warrant.id : this.warrantId
            this.platform_reference_number = res.data.warrant.platform_reference_number
            this.warrant_code = res.data.warrant.warrant_code
            this.items = res.data.warrant.warrant_containers.map(container => ({
              ...container,
              price: (+container.price).toLocaleString('en-GB', { maximumFractionDigits: 2 }),
              materials: typeof container.materials === 'string' ? JSON.parse(container.materials) : container.materials,
            }))
            this.created_at = res.data.warrant.created_at
            this.removedMaterials = []
            this.snackbar = true
            this.getMaterials(this.location.received_at_location)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
  },
}
</script>
